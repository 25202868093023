<template>
  <v-app>
    <router-view :isAuthorized="isAuthorized" />
  </v-app>
</template>

<script>
import { $bus } from './api/bus'
import requestFactory, { isAuthorized, removeCredintals } from './api/request'
export default {
  name: 'App',
  data() {
    return {
      isAuthorized: false,
    }
  },
  async created() {
    this.isAuthorized = isAuthorized()
    $bus.$on('login-event', isAuthorized => {
      this.isAuthorized = isAuthorized
    })
    let req = requestFactory()
    try {
      let me = await req('/api/users/me')
      if (!me.data) {
        removeCredintals()
      }
    } catch (e) {
      e => console.log(e)
    }
  },
}
</script>
