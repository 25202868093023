<template>
  <v-card color="amber lighten-5">
    <v-card-text>
      <v-container>
        <v-row v-resize="calculatePlotRect">
          <v-col cols="12" md="5">
            <v-simple-table class="amber lighten-5">
              <template v-slot:default>
                <tbody>
                  <tr v-for="item of statuses" :key="item.id">
                    <td>{{ item.name }}</td>
                    <td class="text-right">{{ item.value }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12" md="7"
            ><div style="width: 100%; height: 100%" ref="plotContainer">
              <BarChartSimple
                v-if="plotHeight"
                :data="plotData"
                :options="options"
                :height="plotHeight"
                :width="plotWidth"
              /></div
          ></v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import BarChartSimple from './BarChartSimple.vue'
export default {
  components: { BarChartSimple },
  props: {
    statuses: Array,
  },
  data() {
    return {
      plotHeight: 0,
      plotWidth: 0,
    }
  },
  computed: {
    plotData() {
      const res = []
      //      console.log('plotData')
      //      console.log(this.statuses)
      this.statuses.forEach(d => {
        if (d.name === 'Не поступил') {
          res[0] = { name: 'Не поступил', value: d.value }
        }
        if (d.name === 'Проверка ЦЭККМП' || d.name === 'Представлен в ЦЭККМП') {
          res[1] = res[1]
            ? { ...res[1], value: res[1].value + d.value }
            : { name: 'В процессе проверки', value: d.value }
        }
        if (d.name === 'Отправлен на доработку') {
          res[2] = { name: 'На доработке', value: d.value }
        }
        if (
          d.name === 'Положительное заключение ЦЭККМП' ||
          d.name === 'На рассмотрении НПС' ||
          d.name === 'Ожидается для размещения' ||
          d.name === 'Размещен в рубрикаторе'
        ) {
          res[3] = res[3]
            ? { ...res[3], value: res[3].value + d.value }
            : { name: 'Соответствует требованиям', value: d.value }
        }
      })

      let c = res.reduce((c, d) => (c += d.value), 0)
      const plotData = {
        years: res.map(d => d.name),
        values: res.map(d => d.value / c),
      }
      return plotData
    },
    options() {
      return {
        valueType: 'percent',
      }
    },
  },
  watch: {
    statuses() {
      this.calculatePlotRect()
    },
  },
  mounted() {
    this.calculatePlotRect()
  },
  methods: {
    calculatePlotRect() {
      //      this.$nextTick(() => {
      this.plotHeight = this.$refs.plotContainer.clientHeight // - 6
      this.plotWidth = this.$refs.plotContainer.clientWidth
      //      console.log(this.$refs.plotContainer.clientHeight)
      //      })
    },
  },
}
</script>

<style></style>
