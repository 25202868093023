import { select } from 'd3'

const wrap = (text, width2) => {
  text.each(function () {
    const text2 = select(this)
    const words = text2.text().split(/\s+/).reverse()
    let word
    let line = []
    const lineHeight = 1.0 // ems
    const y = text2.attr('y') ? text2.attr('y') : '0'
    const x = text2.attr('x') ? text2.attr('x') : '0'
    const dy = parseFloat(text2.attr('dy'))
    let tspan = text2
      .text(null)
      .append('tspan')
      .attr('x', x)
      .attr('y', y)
      .attr('dy', `${dy}em`)
    //      .attr('dx', `${dx}em`);
    word = words.pop()
    while (word) {
      line.push(word)
      tspan.text(line.join(' '))
      if (tspan.node().getComputedTextLength() > width2) {
        line.pop()
        tspan.text(line.join(' '))
        line = [word]
        tspan = text2
          .append('tspan')
          .attr('x', x)
          .attr('y', y)
          .attr('dy', `${lineHeight + dy}em`)
          .text(word)
      }
      word = words.pop()
    }
  })
}

export default wrap
