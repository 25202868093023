<template>
  <CrLayout :isAuthorized="isAuthorized">
    <v-main>
      <CrTable :isAuthorized="isAuthorized" />
    </v-main>
  </CrLayout>
</template>

<script>
import CrTable from '../components/CrTable'
import CrLayout from '../components/CrLayout.vue'

export default {
  name: 'HomeView',
  props: {
    isAuthorized: Boolean,
  },
  components: {
    CrTable,
    CrLayout,
  },
}
</script>
