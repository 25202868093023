<template>
  <svg :viewBox="viewBox" :style="containerStyle">
    <g class="content" :transform="contentTranslate"></g>
    <g class="xAxis" :transform="xAxisTranslate"></g>
  </svg>
</template>

<script>
import {
  select,
  scaleBand,
  scaleLinear,
  //  axisBottom,
  max,
  format,
  formatDefaultLocale,
  //  event as d3event,
} from 'd3'
import wrap from '../api/wrap'
import * as customScales from '../api/scale'

export default {
  components: {},
  props: {
    height: {
      type: Number,
      // todo: Высоту сделал подгоном. Нужно будет поработать с высотой.
      default: 230,
    },
    width: {
      type: Number,
      default: 450,
    },
    data: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    events: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      /*
        Это расстояние от границ SVG внутрь до границ области графика.
        Логичнее назвать это padding, но документация и примеры к D3 называют это margin.
        Для единообразия используем margin
      */
      margin: {
        left: 20,
        top: 30,
        right: 20,
        bottom: 35,
      },
      // Собирать начальный lastМalues нужной длинны
      lastValues: [0, 0, 0, 0, 0],
    }
  },
  created() {
    formatDefaultLocale({
      decimal: ',',
      thousands: ' ',
      grouping: [3],
    })
  },
  mounted() {
    this.render()
  },
  watch: {
    data() {
      this.render()
    },
    height() {
      this.render()
    },
    width() {
      this.render()
    },
    options: {
      handler() {
        this.render()
      },
      deep: true,
    },
  },
  computed: {
    containerStyle() {
      return `height: ${this.height}px;width: ${this.width}px;`
    },
    mainPlotAreaHeight() {
      return this.height - this.margin.top - this.margin.bottom
    },
    mainPlotAreaWidth() {
      return this.width - this.margin.left - this.margin.right
    },
    viewBox() {
      return `0 0 ${this.width} ${this.height}`
    },
    svg() {
      return select('svg')
    },
    contentTranslate() {
      return `translate(${this.margin.left}, ${this.margin.top})`
    },
    xAxisTranslate() {
      return `translate(${this.margin.left}, ${
        this.mainPlotAreaHeight + this.margin.top
      })`
    },
    animationDuration() {
      return this.options.animationDuration
        ? this.options.animationDuration
        : 500
    },
    points() {
      return this.data.years.map((d, i) => ({
        x: this.data.years[i],
        y: this.data.values[i],
        active: this.options.activeIndex === i,
      }))
    },
    color() {
      return this.options.color ? this.options.color : 'steelblue'
    },
    activeColor() {
      return this.options.activeColor ? this.options.activeColor : 'firebrick'
    },
    format() {
      const { valueType = 'decimal' } = this.options

      if (valueType === 'decimal') {
        return format('.2~f')
      } else if (valueType === 'integer') {
        return a => parseInt(a)
      } else if (valueType === 'percent') {
        return format('.2~%')
      } else {
        throw Error('Заданный формат преобразования не поддерживается')
      }
    },
    xScale() {
      return scaleBand()
        .domain(this.points.map(d => d.x))
        .range([0, this.mainPlotAreaWidth])
        .paddingInner(0.45)
        .paddingOuter(0.1)
    },
    yScale() {
      return scaleLinear()
        .domain([0, max(this.points.map(d => d.y))])
        .range([0, this.mainPlotAreaHeight])
    },
  },
  methods: {
    render() {
      //      console.log('render')
      const { points, svg, animationDuration, xScale, lastValues } = this
      svg
        .select('g.content')
        .selectAll('g.bar')
        .each((d, i) => (lastValues[i] = d.y))
        .data(points)
        .join(
          enter =>
            enter
              .append('g')
              .classed('bar', true)
              .attr('transform', ({ x }) => `translate(${xScale(x)},0)`)
              .call(this.rectPrepare)
              .call(this.rectAnimation),
          update =>
            update
              .attr('transform', ({ x }) => `translate(${xScale(x)},0)`)
              .call(this.rectAnimation)
        )
      svg
        .select('g.xAxis')
        .transition('axisAnimation')
        .duration(animationDuration)
        .call(customScales.axisBottom(xScale))
        .selectAll('.tick text')
        .call(wrap, xScale.step())
    },
    changeOpacity(element, opacity) {
      element
        .transition('changeOpacity')
        .duration(this.animationDuration)
        .attr('fill-opacity', opacity)
    },
    bindEvents(element, events) {
      if (typeof events !== 'object') return
      Object.keys(events).forEach(e => {
        element.on(e, events[e])
      })
    },
    rectPrepare(s) {
      const { mainPlotAreaHeight, xScale } = this
      s.append('rect')
        .attr('x', 0)
        .attr('y', mainPlotAreaHeight)
        .style(
          'stroke-dasharray',
          `${xScale.bandwidth()}, ${xScale.bandwidth()}`
        )
        .attr('fill-opacity', 0.3)
        .style('stroke-width', 0.8)
        .attr('width', xScale.bandwidth())
        .call(this.bindEvents, this.events)
        .on('mouseover.internal', e =>
          select(e.target).call(this.changeOpacity, 0.5)
        )
        .on('mouseout.internal', e =>
          select(e.target).call(this.changeOpacity, 0.3)
        )
      s.append('text')
        .attr('x', xScale.bandwidth() / 2)
        .attr('y', mainPlotAreaHeight)
        .attr('text-anchor', 'middle')
        .attr('font-weight', '450')
        .attr('fill', 'grey')

      return s
    },
    rectAnimation(s) {
      const {
        animationDuration,
        activeColor,
        color,
        mainPlotAreaHeight,
        format,
        xScale,
        yScale,
        lastValues,
      } = this
      s.select('g.bar').call(d =>
        d.attr('transform', ({ x }) => `translate(${xScale(x)},0)`)
      )
      s.select('g.bar rect')
        .transition('rectAnimation')
        .duration(animationDuration)
        .attr('y', ({ y }) => mainPlotAreaHeight - yScale(y))
        .attr('width', xScale.bandwidth())
        .style(
          'stroke-dasharray',
          `${xScale.bandwidth()}, ${xScale.bandwidth()}`
        )
        .attr('height', ({ y }) => yScale(y))
        .style(
          'stroke-dasharray',
          ({ y }) => `${xScale.bandwidth() + yScale(y)}, ${xScale.bandwidth()}`
        )
        .attr('fill', ({ active }) => (active ? activeColor : color))
        .style('stroke', ({ active }) => (active ? activeColor : color))
      s.select('g.bar text')
        .transition('textAnimation')
        .duration(animationDuration)
        .attr('y', ({ y }) => mainPlotAreaHeight - yScale(y) - 10)
        .attr('x', xScale.bandwidth() / 2)
        .textTween((d, i) => t => {
          return format(lastValues[i] + (d.y - lastValues[i]) * t)
        })
      return s
    },
  },
}
</script>

<style scoped>
svg >>> rect {
  cursor: pointer;
}
svg >>> .xAxis {
  stroke-width: 0.5;
}
svg >>> .xAxis path {
  color: grey;
}
svg {
  display: block;
}
</style>
