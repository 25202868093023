<template>
  <v-card flat>
    <v-toolbar color="primary" dark extended flat>
      <v-spacer></v-spacer
      ><v-btn v-if="isAuthorized" class="ma-2" text icon @click="logout"
        ><v-icon>mdi-account-check</v-icon></v-btn
      >
    </v-toolbar>

    <v-card class="mx-auto" max-width="1400" style="margin-top: -64px">
      <v-toolbar height="96" flat>
        <v-toolbar-title class="">
          Контроль исполнения поручения Правительства РФ
          <div class="subtitle-2 grey--text">
            по разработке, одобрению и утверждению клинических рекомендаций<br />
            по заболеваниям, состояниям, включенным в Перечень
          </div>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-text-field
          label="Поиск"
          prepend-icon="mdi-magnify"
          @input="$root.$emit('search-table', $event)"
          hide-details
          clearable
        ></v-text-field>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text>
        <slot />
      </v-card-text>
    </v-card>
    <v-footer class="pa-0 mt-5">
      <v-card flat tile width="100%" class="primary text-center">
        <v-card-text class="white--text ma-2">
          © {{ new Date().getFullYear() }} ФГБУ "Центр экспертизы и контроля
          качества медицинской помощи" Минздрава России
        </v-card-text>
      </v-card>
    </v-footer>
    <AwaitDialog
      title="Подтверждение"
      description="Выйти из программы?"
      ref="dialog"
    />
  </v-card>
</template>

<script>
import { authServerLogout } from '../api/request'
import AwaitDialog from './AwaitDialog.vue'

export default {
  name: 'CrLayout',
  components: { AwaitDialog },
  props: {
    isAuthorized: Boolean,
  },
  methods: {
    async logout() {
      if (await this.$refs.dialog.open()) {
        await authServerLogout(false)
      }
    },
  },
}
</script>
