import { $bus } from './bus'
import { setOnChangeCredintals, configureCredintals } from './request'

if (localStorage.getItem('credintals')) {
  let credintals = JSON.parse(localStorage.getItem('credintals'))
  configureCredintals(credintals?.token)
}

setOnChangeCredintals(credintals => {
  localStorage.setItem('credintals', JSON.stringify(credintals))
  $bus.$emit('login-event', !!credintals?.token)
})
