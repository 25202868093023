<template>
  <v-container>
    <CrSummary v-if="calcStatuses.length" :statuses="calcStatuses" />
    <v-data-table
      :headers="headers"
      :items="goodCrs"
      show-expand
      :search="search"
      item-key="id"
      :items-per-page="15"
      class="main-table"
    >
      <template v-slot:[`item.name`]="{ item }">
        <div>{{ item.name }}</div>
        <div class="grey--text">
          {{ [item.mkb, item.ageGroup].join(' - ') }}
        </div>
      </template>
      <template v-slot:[`item.lastStatus`]="{ item }">
        <div>{{ item.lastStatus }}</div>
        <div class="grey--text">
          {{
            [item.lastStatusDate, item.lastStatusComment]
              .filter(d => d)
              .join(' - ')
          }}
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-sheet class="ma-6">
            <v-list-item
              v-for="workflow of item.actual_workflow"
              :key="workflow.id"
            >
              <v-list-item-content>
                <v-list-item-title>{{ workflow.statusText }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  [workflow.dateTimeDisplayed, workflow.comment]
                    .filter(d => d)
                    .join(' - ')
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="isAuthorized">
                <v-btn icon @click="removeWorkflow(workflow.id)">
                  <v-icon color="grey lighten-1">mdi-close-circle</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <template v-if="isAuthorized">
              <v-list-item v-if="!additionals[item.id].formShown">
                <v-btn icon @click="plusClick(item.id)">
                  <v-icon color="grey lighten-1">mdi-plus-circle</v-icon>
                </v-btn>
              </v-list-item>
              <v-container v-else fluid>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      label="Статус"
                      v-model="additionals[item.id].newWorkflow.statusText"
                      :items="goodStatuses"
                      autocomplete
                      clearable
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="additionals[item.id].newWorkflow.dateTime"
                          label="Дата"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="additionals[item.id].newWorkflow.dateTime"
                        no-title
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      label="Комментарий"
                      v-model="additionals[item.id].newWorkflow.comment"
                      rows="2"
                      auto-grow
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-btn
                    :disabled="
                      !(
                        additionals[item.id].newWorkflow.statusText &&
                        additionals[item.id].newWorkflow.dateTime
                      )
                    "
                    @click="
                      addWorkflow(item.id, additionals[item.id].newWorkflow)
                    "
                    >Отправить</v-btn
                  >
                </v-row>
              </v-container>
            </template>
          </v-sheet>
        </td>
      </template>
    </v-data-table>
    <AwaitDialog
      title="Подтверждение"
      :description="description"
      ref="dialog"
    />
  </v-container>
</template>

<script>
import axios from 'axios'
import AwaitDialog from './AwaitDialog.vue'
import CrSummary from './CrSummary.vue'

export default {
  components: { AwaitDialog, CrSummary },
  props: {
    isAuthorized: Boolean,
  },
  data() {
    return {
      search: '',
      crs: [],
      statuses: [],
      additionals: {},
      headers: [
        {
          text: '',
          value: 'id',
        },
        {
          text: 'Наименование',
          value: 'name',
        },
        { text: 'Разработчик', value: 'developer' },
        { text: 'Статус', value: 'lastStatus', width: '20%' },
        // { text: 'Возрастные группы', value: 'ageGroup' },
        // { text: 'Код МКБ', value: 'mkb' },
        { text: '', value: 'data-table-expand' },
      ],
      menu: false,
      date: null,
      dialog: false,
      localWorkflowId: null,
      description: '',
    }
  },
  created() {
    //    console.log(new Date(Date.now()).toLocaleDateString())
    axios.get('/api/cr').then(r => {
      //      console.log(r.data)
      r.data.forEach(d => {
        d.actual_workflow.forEach(d => {
          d.dateTimeDisplayed =
            d.dateTime &&
            (new Date(d.dateTime) > new Date('2021-06-07T23:59:59') ||
              this.isAuthorized)
              ? new Date(d.dateTime).toLocaleDateString()
              : 'до 08.06.2021'
        })
      })

      this.crs = r.data
      return this.crs
    })
    axios.get('/api/workflow/statuses').then(r => (this.statuses = r.data))
    this.$root.$on('search-table', s => {
      this.search = s
    })
  },
  watch: {
    crs: {
      handler() {
        this.crs.forEach(d => {
          this.additionals[d.id] = {
            formShown: !!this.additionals[d.id]?.formShown,
            newWorkflow: this.additionals[d.id]?.newWorkflow ?? {
              statusText: '',
              comment: '',
              dateTime: '',
            },
          }
        })
      },
    },
  },
  computed: {
    goodCrs() {
      return this.crs.map(d => {
        let last_workflow = d.actual_workflow[d.actual_workflow.length - 1]
        // d.actual_workflow.forEach(d => {
        //   d.dateTime = d.dateTime
        //     ? new Date(d.dateTime) > new Date('2021-06-07 23:59:59') &&
        //       new Date(d.dateTime).toLocaleDateString()
        //     : null
        // })
        if (last_workflow) {
          d.lastStatus = last_workflow.statusText
          d.lastStatusDate = last_workflow.dateTimeDisplayed
          d.lastStatusComment = last_workflow.comment
        } else {
          d.lastStatus = null
          d.lastStatusDate = null
          d.lastStatusComment = null
        }
        return d
      })
    },
    goodStatuses() {
      return this.statuses.map(d => d.name)
    },
    calcStatuses() {
      return this.statuses.map(d => d)
    },
  },
  methods: {
    async removeWorkflow(workflowId) {
      this.description = 'Удалить запись?'
      if (await this.$refs.dialog.open()) {
        axios.post('/api/workflow/remove', { id: workflowId }).then(
          r => {
            let item = this.crs.find(d => d.id === r.data.crId)
            //            console.log(item)
            item.actual_workflow.splice(
              item.actual_workflow.findIndex(d => d.id === r.data.id),
              1
            )
          },
          e => console.log(e)
        )
      }
    },
    addWorkflow(id, item) {
      axios
        .post('/api/workflow/add', {
          ...item,
          statusText: item.statusText,
          crId: id,
        })
        .then(
          r => {
            let currensIndex = this.crs.findIndex(d => d.id === id)
            if (currensIndex !== -1) {
              let d = r.data
              d.dateTimeDisplayed =
                d.dateTime &&
                (new Date(d.dateTime) > new Date('2021-06-07T23:59:59') ||
                  this.isAuthorized)
                  ? new Date(d.dateTime).toLocaleDateString()
                  : 'до 08.06.2021'
              let item = this.crs[currensIndex].actual_workflow
              item.push(r.data)
              item.sort((a, b) => {
                if (
                  new Date(a.dateTime) < new Date(b.dateTime) ||
                  (new Date(a.dateTime) === new Date(b.dateTime) && a.id < b.id)
                ) {
                  return -1
                }
                if (
                  new Date(a.dateTime) > new Date(b.dateTime) ||
                  (new Date(a.dateTime) === new Date(b.dateTime) && a.id > b.id)
                ) {
                  return 1
                }
                return 0
              })
              this.additionals[id] = {
                formShown: false,
                newWorkflow: {
                  statusText: '',
                  comment: '',
                  dateTime: '',
                },
              }
            }
          },
          e => console.log(e)
        )
    },
    plusClick(id) {
      this.additionals[id].formShown = !this.additionals[id].formShown
      this.additionals = { ...this.additionals }
    },
    rand() {
      return Math.round(Math.random() * 100)
    },
  },
}
</script>

<style scoped></style>
