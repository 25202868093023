import axios from 'axios'

const refreshTokenUrl = null
const loginPage = '/login'
const defaultRedirect = '/'
const serverLoginRoute = '/api/auth/login'
const serverCallbackRoute = null
const serverLogoutRoute = '/api/auth/logout'

let tokenRequestPromise = Promise.resolve()
let onChangeToken = null
let onChangeRedirect = null
let token = null
let refreshToken = null

//let loginPage = "/auth-server-callback";
//let needAuthorize = true;
let redirect = defaultRedirect

const setHeaders = () => {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
  }
}

const requestNewToken = async () => {
  const tokenRequest = await axios({
    method: 'post',
    data: {
      refresh_token: refreshToken,
    },
    url: refreshTokenUrl,
  })
  configureCredintals(
    tokenRequest.data.access_token,
    tokenRequest.data.refresh_token
  )
}

const requestFactory = function (options = {}) {
  /**
   * options
   * skipAuth - true, если для данного запроса авторизация не требуется
   */
  const { skipAuth, singleton, cancelable } = options
  let cancelToken
  let request
  return async (url, method = 'GET', data = {}) => {
    const params = { url, method, data }
    if (!skipAuth) {
      if (tokenRequestPromise) {
        await tokenRequestPromise
        tokenRequestPromise = null
      }
    }
    if (cancelable) {
      if (cancelToken) {
        cancelToken.cancel(
          'Запрос прерван перед выполнением другого запроса из серии'
        )
      }
      cancelToken = axios.CancelToken.source()
      params.cancelToken = cancelToken.token
    }
    try {
      if (!singleton || !request) {
        request = axios(params)
        return await request
      }
    } catch (e) {
      if (e?.response?.status === 401) {
        try {
          if (!tokenRequestPromise) tokenRequestPromise = requestNewToken()
          await tokenRequestPromise
          return await axios(params)
        } catch (e) {
          removeCredintals()
          setRedirect(window.location.pathname)
          if (window.location.pathname !== loginPage)
            window.location = loginPage
        }
      } else {
        throw e
      }
    }
  }
}
const setCredintals = credintals => {
  token = credintals.token
  refreshToken = credintals.refreshToken
  setHeaders()
}

export default requestFactory
export const setOnChangeCredintals = callback => {
  onChangeToken = callback
}

export const authServerLogin = async () => {
  //  await tokenRequestPromise;
  const redirectUrl = await axios.get(serverLoginRoute)
  window.location = redirectUrl.data.redirect
}
export const authServerCallback = async () => {
  //  await tokenRequestPromise;
  let r = await axios.get(
    `${serverCallbackRoute}?` + new URLSearchParams(location.search)
  )
  configureCredintals(r.data.access_token, r.data.refresh_token)
  window.location = getRedirect()
  setRedirect(null)
}
export const authServerLogout = async (redirect = true) => {
  //  await tokenRequestPromise;
  const req = requestFactory()
  await req(serverLogoutRoute, 'post')
  configureCredintals(null, null)
  if (redirect) {
    setRedirect(location.pathname)
    location = loginPage
  }
}
export const setOnChangeRedirect = callback => {
  onChangeRedirect = callback
}
export const configureCredintals = (new_token, new_refreshToken) => {
  //  console.log("configureCredintals");
  setCredintals({
    token: new_token,
    refreshToken: new_refreshToken,
  })
  if (onChangeToken) {
    onChangeToken({
      token,
      refreshToken,
    })
  }
}
export const isAuthorized = () => !!token
export const removeCredintals = () => configureCredintals(null, null)
export const getRedirect = () => redirect
export const setRedirect = new_redirect => {
  if (onChangeRedirect) {
    onChangeRedirect(new_redirect)
  }
  redirect = new_redirect ? new_redirect : defaultRedirect
}
