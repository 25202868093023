var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.calcStatuses.length)?_c('CrSummary',{attrs:{"statuses":_vm.calcStatuses}}):_vm._e(),_c('v-data-table',{staticClass:"main-table",attrs:{"headers":_vm.headers,"items":_vm.goodCrs,"show-expand":"","search":_vm.search,"item-key":"id","items-per-page":15},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"grey--text"},[_vm._v(" "+_vm._s([item.mkb, item.ageGroup].join(' - '))+" ")])]}},{key:"item.lastStatus",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.lastStatus))]),_c('div',{staticClass:"grey--text"},[_vm._v(" "+_vm._s([item.lastStatusDate, item.lastStatusComment] .filter(function (d) { return d; }) .join(' - '))+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-sheet',{staticClass:"ma-6"},[_vm._l((item.actual_workflow),function(workflow){return _c('v-list-item',{key:workflow.id},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(workflow.statusText))]),_c('v-list-item-subtitle',[_vm._v(_vm._s([workflow.dateTimeDisplayed, workflow.comment] .filter(function (d) { return d; }) .join(' - ')))])],1),(_vm.isAuthorized)?_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeWorkflow(workflow.id)}}},[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-close-circle")])],1)],1):_vm._e()],1)}),(_vm.isAuthorized)?[(!_vm.additionals[item.id].formShown)?_c('v-list-item',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.plusClick(item.id)}}},[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-plus-circle")])],1)],1):_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Статус","items":_vm.goodStatuses,"autocomplete":"","clearable":""},model:{value:(_vm.additionals[item.id].newWorkflow.statusText),callback:function ($$v) {_vm.$set(_vm.additionals[item.id].newWorkflow, "statusText", $$v)},expression:"additionals[item.id].newWorkflow.statusText"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Дата","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.additionals[item.id].newWorkflow.dateTime),callback:function ($$v) {_vm.$set(_vm.additionals[item.id].newWorkflow, "dateTime", $$v)},expression:"additionals[item.id].newWorkflow.dateTime"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.additionals[item.id].newWorkflow.dateTime),callback:function ($$v) {_vm.$set(_vm.additionals[item.id].newWorkflow, "dateTime", $$v)},expression:"additionals[item.id].newWorkflow.dateTime"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Комментарий","rows":"2","auto-grow":""},model:{value:(_vm.additionals[item.id].newWorkflow.comment),callback:function ($$v) {_vm.$set(_vm.additionals[item.id].newWorkflow, "comment", $$v)},expression:"additionals[item.id].newWorkflow.comment"}})],1)],1),_c('v-row',[_c('v-btn',{attrs:{"disabled":!(
                      _vm.additionals[item.id].newWorkflow.statusText &&
                      _vm.additionals[item.id].newWorkflow.dateTime
                    )},on:{"click":function($event){return _vm.addWorkflow(item.id, _vm.additionals[item.id].newWorkflow)}}},[_vm._v("Отправить")])],1)],1)]:_vm._e()],2)],1)]}}],null,true)}),_c('AwaitDialog',{ref:"dialog",attrs:{"title":"Подтверждение","description":_vm.description}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }